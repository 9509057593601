import React, { useState } from 'react';
import {
  Image,
  Modal,
  ModalContent,
  Segment,
  List,
  ListItem,
  Label,
  Icon,
  MessageList,
  MessageItem,
  Message,
  MessageHeader,
  Accordion,
  AccordionTitle,
  AccordionContent,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { getLocalServerConfig } from '../../services/Server';
import ntfyLogo from '../../assets/img/ntfy/ntfy-logo.png';
import ntfySubscribe from '../../assets/img/ntfy/ntfy-subscribe.png';
import ntfyGenerate from '../../assets/img/ntfy/ntfy-generate.png';
import ntfyTopicName from '../../assets/img/ntfy/ntfy-topic-name.png';
import androidNtfyHome from '../../assets/img/ntfy/android_ntfy_home.png';
import androidNtfyDone from '../../assets/img/ntfy/android_ntfy_done.png';
import androidNtfyHomeSettings from '../../assets/img/ntfy/android_ntfy_home_settings.png';
import androidNtfyHomeDone from '../../assets/img/ntfy/android_ntfy_home_done.png';
import androidNtfySubscribe from '../../assets/img/ntfy/android_ntfy_subscribe.png';
import androidNtfyTopicRename from '../../assets/img/ntfy/android_ntfy_topic_rename.png';
import androidNtfyTopicSettings from '../../assets/img/ntfy/android_ntfy_topic_settings.png';

function NtfyGuideModal({ setOpen, open }) {
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleSetActiveIndex = (index) => setActiveIndex(activeIndex === index ? -1 : index);

  return (
    <Modal
      dimmer="blurring"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      closeIcon
    >
      <Segment basic size="big" textAlign="center">
        <Image src={ntfyLogo} size="tiny" circular centered />
        <br />
        Ntfy sur votre smartphone iOS/Android
      </Segment>
      <ModalContent scrolling>
        <Segment>
          Ntfy vous permet de recevoir des notifications de{' '}
          {getLocalServerConfig().siteName || 'Ergo'} directement sur votre smartphone.
          <br />
          <br />
          <b className="blink">
            Les notifications concernent:
            <br />
            - les téléchargements que vous lancez et ceux pour lesquels vous décidez d&apos;être
            notifié(e)
            <br />- les séries que vous cochez dans l&apos;onglet{' '}
          </b>
          <Label size="small" color="teal">
            <Icon name="checked calendar" />
            Mes Abonnements
          </Label>
          <br />
          <br />
          Ces notifications surviennent lorsqu&apos;un:
          <List bulleted>
            <ListItem>
              nouvel épisode de cette série est disponible dans{' '}
              <Label size="small" color="teal">
                <Icon name="download" />
                Téléchargements
              </Label>
            </ListItem>
            <ListItem>
              téléchargement est démarré vers votre{' '}
              <Label size="small" color="teal">
                <Icon name="server" />
                NAS Synology / Freebox
              </Label>{' '}
              (si vous avez configuré le topic Ntfy de{' '}
              <Link to="/devices">votre NAS / Freebox</Link>)
            </ListItem>
          </List>
        </Segment>
        <Segment>
          <Accordion fluid styled>
            <AccordionTitle active={activeIndex === 0} onClick={() => handleSetActiveIndex(0)}>
              Création de votre topic personnel sur Ntfy.sh
            </AccordionTitle>
            <AccordionContent active={activeIndex === 0}>
              <MessageList>
                <MessageItem>
                  <Message>
                    <p>
                      Si vous avez déjà créé un topic par le passé, vous pouvez réutiliser le nom de
                      topic affiché sur <b>{getLocalServerConfig().siteName || 'Ergo'}</b> sur la
                      page <Link to="/account">Mon compte</Link> et passer directement à
                      l&apos;étape de configuration de votre téléphone.
                    </p>
                  </Message>
                </MessageItem>
                <MessageItem>
                  Rendez vous sur{' '}
                  <Link target="_blank" to="https://ntfy.sh/app">
                    https://ntfy.sh/app
                  </Link>{' '}
                  (il n&apos;est <b>pas nécessaire</b> de créer un compte)
                </MessageItem>
                <MessageItem>
                  Sur le menu latéral gauche, cliquez sur <Label>+ S&apos;abonner au sujet</Label>
                  <br />
                  <br />
                  <Image src={ntfySubscribe} size="medium" verticalAlign="middle" />
                </MessageItem>
                <MessageItem>
                  Cliquez sur le bouton <Label>Générer un nom</Label>
                  <Message warning>
                    <MessageHeader>Attention !</MessageHeader>
                    <p>
                      Ce nom de topic doit rester privé, aléatoire et impossible à deviner, dans la
                      mesure ou il permet à n&apos;importe qui de s&apos;abonner à votre fil de
                      notifications.
                    </p>
                  </Message>
                  <Image src={ntfyGenerate} size="medium" verticalAlign="middle" />
                </MessageItem>
                <MessageItem>
                  Copiez le nom de topic qui a été généré
                  <br />
                  <br />
                  <Image src={ntfyTopicName} size="medium" verticalAlign="middle" />
                </MessageItem>
                <MessageItem>
                  Cliquez sur le bouton <Label>S&apos;abonner</Label>
                </MessageItem>
                <MessageItem>
                  Sur la page <Link to="/account">Mon Compte</Link>, dans la partie Notifications, à
                  la ligne{' '}
                  <b>
                    <Icon name="mobile alternate" /> Mobile par Ntfy
                  </b>{' '}
                  activez les notifications mobile au bout de la ligne, puis cliquez sur le{' '}
                  <Icon name="pencil" />, collez le nom du topic que vous avez généré précédemment
                  et validez la modification.
                  <Message warning>
                    <MessageHeader>Attention !</MessageHeader>
                    Le nom du sujet est sensible à la casse (Aa) !
                  </Message>
                </MessageItem>
                <MessageItem>
                  Si vous souhaitez configurer les notifications pour votre{' '}
                  <Label size="small" color="teal">
                    <Icon name="server" />
                    NAS Synology / Freebox
                  </Label>{' '}
                  rendez vous sur la page <Link to="/devices">Mes Appareils</Link>, éditez
                  l&apos;appareil et rentrez le nom de topic dans le champs{' '}
                  <b>Topic de notification Ntfy</b>.
                  <br />
                  <Message>
                    <p>
                      <Icon name="exclamation" />
                      Vous pouvez utiliser le même nom de topic pour votre compte et pour votre{' '}
                      <Label size="small" color="teal">
                        <Icon name="server" />
                        NAS Synology / Freebox
                      </Label>
                    </p>
                  </Message>
                </MessageItem>
                <MessageItem>
                  Téléchargez l&apos;application sur votre mobile et passez à l&apos;étape suivante.
                </MessageItem>
              </MessageList>
            </AccordionContent>
            <AccordionTitle active={activeIndex === 1} onClick={() => handleSetActiveIndex(1)}>
              Configuration sur Android
            </AccordionTitle>
            <AccordionContent active={activeIndex === 1}>
              <MessageList>
                <MessageItem>
                  Téléchargez l&apos;application Android depuis le Google Play Store:
                  <br />
                  <br />
                  <Link to="https://play.google.com/store/apps/details?id=io.heckel.ntfy">
                    https://play.google.com/store/apps/details?id=io.heckel.ntfy
                  </Link>
                </MessageItem>
                <MessageItem>
                  Lancez l&apos;application Ntfy et appuyez sur le + en bas de l&apos;écran
                  d&apos;accueil
                  <br />
                  <br />
                  <Image src={androidNtfyHome} size="medium" verticalAlign="middle" />
                </MessageItem>
                <MessageItem>
                  Rentrez le nom de topic que vous avez généré dans la case <b>Nom de sujet</b> et
                  appuyez sur <Label>Abonner</Label>
                  <br />
                  <Message warning>
                    <MessageHeader>Attention !</MessageHeader>
                    Le nom du sujet est sensible à la casse (Aa) !
                  </Message>
                  <Image src={androidNtfySubscribe} size="medium" verticalAlign="middle" />
                </MessageItem>
                <MessageItem>
                  Accédez au topic nouvellement créé et appuyez en haut à droite sur &quot;
                  <b>...</b>&quot; puis <b>Paramètres d&apos;abonnement</b>
                  <br />
                  <br />
                  <Image src={androidNtfyHomeDone} size="medium" verticalAlign="middle" />{' '}
                  <Image src={androidNtfyHomeSettings} size="medium" verticalAlign="middle" />
                </MessageItem>
                <MessageItem>
                  Dans la catégorie <b>Apparence</b>, appuyez sur <b>Nom d&apos;affichage</b>, tapez{' '}
                  <b>{getLocalServerConfig().siteName || 'Ergo'}</b> puis validez par <b>OK</b>
                  <br />
                  <br />
                  <Image src={androidNtfyTopicSettings} size="medium" verticalAlign="middle" />{' '}
                  <Image src={androidNtfyTopicRename} size="medium" verticalAlign="middle" />
                </MessageItem>
                <MessageItem>
                  Rendez-vous sur <b>{getLocalServerConfig().siteName || 'Ergo'}</b> dans la page{' '}
                  <b>Mon Compte</b> et appuyez sur le bouton <b>Tester</b> pour confirmer le bon
                  fonctionnement des notifications.
                  <br />
                </MessageItem>
                <MessageItem>
                  Votre appareil est prêt!
                  <br />
                  <br />
                  <Image src={androidNtfyDone} size="medium" verticalAlign="middle" />
                </MessageItem>
                <MessageItem>
                  A très vite sur {getLocalServerConfig().siteName || 'Ergo'}!
                  <br />
                  <br />
                </MessageItem>
              </MessageList>
            </AccordionContent>
            <AccordionTitle active={activeIndex === 2} onClick={() => handleSetActiveIndex(2)}>
              Configuration sur Apple iOS
            </AccordionTitle>
            <AccordionContent active={activeIndex === 2}>
              <MessageList>
                <MessageItem>
                  Téléchargez l&apos;application iOS sur l&apos;AppStore:{' '}
                  <Link to="https://apps.apple.com/us/app/ntfy/id1625396347">
                    https://apps.apple.com/us/app/ntfy/id1625396347
                  </Link>
                </MessageItem>
                <MessageItem>
                  Pour la suite, réferrez vous à la{' '}
                  <b>
                    <span className="clickable" onClick={() => handleSetActiveIndex(1)}>
                      Configuration sur Android
                    </span>
                  </b>{' '}
                  qui ressemble à l&apos;interface iOS
                </MessageItem>
              </MessageList>
            </AccordionContent>
          </Accordion>
        </Segment>
      </ModalContent>
    </Modal>
  );
}

export default NtfyGuideModal;
